@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Allerta+Stencil&display=swap');

    #Hamburger_Menu {
        display: block;
        justify-content: flex-start;
    }
    .navBar {
        position: relative;
    }
    .navBar #openCloseButton {
        position: fixed;
        left: 25px;
        top: 50px;
        z-index: 9999;
        cursor: pointer;
        background: transparent;
        border: none;
    }
    .menuNav {
        display: none;
    }
    .menuNav.showMenu {
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        overflow-y: scroll;
        list-style: none;
        position: fixed;
        overflow: hidden;
        top: 15%;
        left: 0;
        bottom: 0;
        height: 88vh;
        max-width: 290px;
        z-index: 9;
        border-top-right-radius: 2.5em;
        padding-left: 0;
    }
    .menuNav li:first-child {
        margin-top: 3.5rem;
    }
    /* .menuNav li:last-child {
        margin-bottom: 3.5em;
    } */
    .menuNav.showMenu li {
        width: 100%;
    }
    .navIcons {
        margin-right: 10%;
    }
    ul li a {
        display: block;
        border: 1px solid transparent;
        padding: 10px 40px;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: bold;
    }
    #menu_toggle {
        padding: 10px 120px;
    }
    @media (max-width: 860px) {
        .navBar #openCloseButton {
            left: 5px;
            top: 15px;
        }
    }