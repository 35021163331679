body {
    background-size: 100%;
    background-repeat: no-repeat;
}
p {
    font-size: small;
}
#setupModal .loginModalForm {
width: 45%;
}
#setupModal .loginModalLogo {
    width: 45%;
}
.modalFooterWrap {
    display: flex;
    justify-content: space-around;
    width: 100%;
}
.modalFooterRight {
    display: flex;
    /* width: 40%; */
    align-items: center;
}
.modalFooterLeft {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    min-height: 50px;
}
.modalFooterRight button {
    margin: 5%;
}
.modal-body {
    color: black;
}
.modalBodyWrap {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.loginModalForm {
    display: flex;
    justify-content: space-around;
    width: 50%;
}
.loginModalLogo {
    width: 20%;
}
.loginModalLogo img {
    width: 100%;
}
.modalChangeLoginOrRegister {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    min-height: 75px;
    width: 100%;
}
#avatarModalBody {
    width: 100%;
    background-color: #2a2a2e;
}
.setupAvatarDiv {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width:40%;
    margin-left:30%;
}
.setupAvatarDiv :last-child {
    margin-top: 5%;
}
.openAvatarWindow {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
}
.modalAvatar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 27%;
}
.modalAvatarImg {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}
.modalAvatarImg img, .modalAvatarImg svg{
    width: 80%;
    margin-left: 10%;
    padding: 5px;
}
.pageWrapper {
    padding-top: 15%;
    padding-bottom: 5%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    margin-bottom:3%;
    min-height: 95vh;
}
.secondaryPageWrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}
.leftDiv {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.leftDiv .card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.leftShiftedText {
    text-align: left;
    margin-left: 1%;
    will-change: contents;
    contain: content;
    font-display: swap;
    text-rendering: optimizeSpeed;
}
.centerDiv {
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.centerDiv button {
    width: 35%;
}
.rightDiv {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.rightDiv .card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.card {
    margin: 2%;
}
.ScratchInnerDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}
.cardBody {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.reviewCardBody {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.homeLeftCard {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.homeGettingStarted {
    margin: 0 0 5% 0;
    width: 45%;
}
.homeHeader {
    margin-top: -1.5%;
    margin-bottom: 1.5%;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.cardInnerDiv .homeLeftCardImageDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 5%;
}
.swiper {
    max-width: 35vw;
    max-height: 80vh;
    display: flex;
    width: 100%;
    border-radius: .25em;
}
.swiperSlideDiv {
    max-width: 98%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 2%;
    margin-left: 1%;
    border: 2px solid #114B5F;
    border-radius: .25em;
}
.swiper-pagination-bullet {
    background-color: #F45B69;
}
.subscriptionPageWrap {
    display: flex;
    justify-content: space-evenly;
}
.subscriptionPageWrap .card {
    width: 30%;
}
.createPageImages {
    width: 65%;
    border-radius: 1.5em;
    margin-bottom: 2.5%;
}
.cardInnerDiv {
    border: 1px solid lightgrey;
    border-radius: .25em;
    padding: 2%;
    text-align: center;
    height: 100%;
    width: 100%;
}
#jobDescriptionCardInnerDiv {
    width: 55%;
}
#scratchCardInnerDiv {
    width: 55%;
}
.cardInnerDiv .alignLeft {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left:10%;
}
.tiersSegments {
    border: 1px solid lightgrey;
    border-radius: .25em;
    padding: 2%;
    text-align: center;
    height: 100%;
}
.tiersSegments ul {
    list-style: none;
}
.tiersSegments h3 {
    font-weight: bold;
}
.tiersSegments h5 {
    font-weight: bold;
}
#subLeftTierSegment {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 70%;
    align-self: center;
    padding: 5px;
}
.cardInnerDiv ul {
    list-style: none;
}
.subscriptionComparisonWrap {
    margin-top: 2%;
}
#paymentProcessorCard {
    min-width: 80%;
}
.text-muted.form-text {
    color: darkgrey !important;
}
.required-note {
    font-size: small;
}
.required-indicator {
    color: red;
    font-size: larger;
}
.FaqWrapper {
    width: 65%;
}
.FaqWrapper h2 {
    font-size: 1.5rem;
    margin-top: 1.5rem;
    font-weight: 600;
}

.FaqWrapper .faq-intro {
    margin-bottom: 2rem;
}

.FaqWrapper div[itemType="https://schema.org/Question"] {
    margin-bottom: 1.5rem;
}
/* default resume styling */

#defaultResumeWrap {
    width: 100%;
    max-width: 924px;
    height: 11.35in;
    overflow: hidden;
    line-height:1;
    background-color: white;
    color: black;
}
#defaultResumeWrap h2 {
    color: black;
}
.reviewDefaultResumeSections {
    padding: 1%;
    width: 90%;
    margin-left: 5%;
}
.leftOrientedReviewSections {
    text-align: left;
}
.indented {
    margin-left: 7.5%;
}
#lastReviewDefaultResumeSection {
    border-bottom: none;
}
.reviewButtonGroup {
    display: flex;
    justify-content: space-evenly;
    width: 50%;
    margin-left: 25%;
    padding: 1%;
}
.reviewDefaultResumeCardWrap {
    width: 70%;
}
.centeredResumeTitle {
    display: flex;
    align-items: center;
    justify-content: center;
}
.floatingDiv {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 10%;
    right:0;
    top:40%;
    padding-right:1%;
}
.floatingDiv ul {
    list-style: none;
}
.floatingDiv ul li {
    padding-bottom: 5%;
}
.floatingLeftDiv {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 10%;
    left: 0;
    top: 50%;
    padding-left: 1%;
}
.reviewResumeJobTitleAndTime {
    display: flex;
    justify-content: space-between;
}
.reviewSize {
    overflow-y: auto;
    max-height: 70vh;
    padding: 1rem;
}

#resumeStyle2 {
    background-color: white;
    color: black;
    width: 100%;
    height: 11.35in;
    overflow: hidden;
    line-height: 1;
}
#resumeStyle2 h2 {
    color: black;
}
#resumeStyle2 h3, #resumeStyle2 h6 {
    margin: 0.3rem 0;
    line-height: 1;
}
#resumeStyle2Header {
    border-bottom: 2px solid black;
}
#resumeStyle2 .reviewDefaultResumeSections {
    border-bottom: none;
}
.resumeStyleLowerWrap {
    display: flex;
    height: 95%;
}
.resumeStyleLeftBlock {
    left: 0;
    display: flex;
    flex-direction: column;
    width: 25%;
    min-height: 100%;
    background-color: lightgray;
    padding-top: 1%;
    text-align: left;
}
.resumeStyleRightBlock {
    width: 75%;
    height: 100%;
    justify-content: space-around;
}
.spacing {
    margin-bottom: 5px;
}

/* Resume Style 2 specific styles */
.resume-style2-container {
  height: 11.35in;
  width: 100%;
  margin: 0 auto;
  font-family: "Helvetica", "Arial", sans-serif;
  color: #333;
  background: #fff;
  padding: 30px;
  box-sizing: border-box;
  font-size: 8pt;
  line-height: 1.2;
}

.resume-style2-header {
  text-align: center;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.resume-style2-name {
  font-size: 16pt;
  font-weight: 700;
  margin: 0;
  margin-bottom: 5px;
  color: black;
}

.resume-style2-job-title {
  font-size: 12pt;
  font-weight: 600;
  margin: 0;
  margin-bottom: 5px;
  color: black;
}

.resume-style2-header-line {
  border-bottom: 1px solid black;
  margin-top: 5px;
}

.resume-style2-content {
  display: flex;
  margin-top: 10px;
}

.resume-style2-left-column {
  width: 25%;
  padding-right: 15px;
  background-color: #e0e0e0;
  padding: 15px;
}

.resume-style2-right-column {
  width: 75%;
  padding-left: 15px;
}

.resume-style2-section {
  margin-bottom: 10px;
}

.resume-style2-section-title {
  font-size: 12pt;
  font-weight: 700;
  margin: 0 0 5px 0;
  text-transform: uppercase;
  color: black;
}

.resume-style2-contact-info {
  display: flex;
  flex-direction: column;
  font-size: 8pt;
}

.resume-style2-contact-info a {
  color: #0000EE; /* Standard hyperlink blue */
  text-decoration: none;
  margin-bottom: 2px;
}

.resume-style2-contact-info a:hover {
  text-decoration: underline;
}

.resume-style2-experience-item {
  margin-bottom: 8px;
}

.resume-style2-company-name {
  font-weight: 700;
  margin: 0;
  font-size: 9pt;
}

.resume-style2-job-position {
  margin: 0;
  font-size: 8pt;
}

.resume-style2-project-item {
  margin-bottom: 8px;
}

.resume-style2-project-title {
  font-weight: 700;
  margin: 0;
  font-size: 9pt;
  margin-bottom: 2px;
}

/* resume style 3 */

#resumeStyle3 {
    background-color: white;
    color: black;
    width: 100%;
    height: 11.35in;
    overflow: hidden;
    line-height: 1;
}
#resumeStyle3 h2{
    color: black;
}
#resumeStyle3 h3, #resumeStyle3 h6 {
    margin: 0.3rem 0;
    line-height: 1;
}
#resumeStyle3ExperiencSection h4 {
    text-align: left;
    padding-left: 2%;
}
#resumeStyle3 .reviewDefaultResumeSections {
    border-bottom: none;
}

#resumeStyle3Skills {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.resumeStyle3Header {
    background-color: lightblue;
    border-radius: .25em;
    padding-bottom: 1%;
}
.reviewSection3LowerSections {
    display: flex;
    flex-direction: row-reverse;
}
.reviewStyle3RightDiv {
    width: 70%;
    height: 100%;
}
.reviewStyle3LeftDiv {
    width: 30%;
    background-color: lightblue;
    min-height: 100%;
    padding-top: 1%;
}
.spreadHorizontally{
    display: flex;
    /* flex-direction: column; */
    width: 100%;
    justify-content: space-evenly;
    align-items: flex-start;
}
.spreadHorizontally div {
    display: flex;
    flex-direction: column;
    /* width: 51%; */
    /* justify-content: space-around; */
    /* align-items: flex-start; */
    /* margin-left: 5px; */
}

/* resume style 4 */

#resumeStyle4 {
    background-color: white;
    color: black;
    width: 100%;
    height: 11.35in;
    overflow: hidden;
    line-height: 1;
}
#resumeStyle4 h2{
    color: black;
    
}
#resumeStyle4 h3, #resumeStyle4 h6 {
    margin: 0.3rem 0;
    line-height: 1;
}
#resumeStyle4Header {
    width: 100%;
    background-color: #7AA1CB;
    padding: 1%;
}
#resumeStyle4 .reviewDefaultResumeSections {
    border-bottom: none;
}
.reviewStyle4LowerWrap {
    display: flex;
    height: 100%;
}
.reviewStyle4LeftDiv {
    width: 70%;
}
.reviewStyle4RightDiv {
    width: 30%;
    background-color: lightgray;
    height: 95%;
    padding-top: 1%;
}

/* Resume Style 4 specific styles */
.resume-style4-container {
  height: 11.35in;
  width: 100%;
  margin: 0 auto;
  font-family: "Helvetica", "Arial", sans-serif;
  color: #333;
  background: #fff;
  padding: 0;
  box-sizing: border-box;
  font-size: 8pt;
  line-height: 1.2;
}

.resume-style4-header {
  margin-bottom: 10px;
  width: 100%;
  background-color: #7AA1CB;
  padding: 15px;
}

.resume-style4-name {
  font-size: 16pt;
  font-weight: 700;
  margin: 0;
  margin-bottom: 8px;
  color: black;
}

.resume-style4-job-title {
  font-size: 12pt;
  font-weight: 600;
  margin: 0;
  margin-bottom: 5px;
  color: black;
}

.resume-style4-content {
  display: flex;
  margin-top: 10px;
}

.resume-style4-left-column {
  width: 70%;
  padding-right: 10px;
  padding-left: 15px;
  padding-top: 10px;
}

.resume-style4-right-column {
  width: 30%;
  background-color: #e0e0e0;
  height: 100%;
  padding-left: 15px;
  padding-top: 10px;
  padding-right: 10px;
}

.resume-style4-section {
  margin-bottom: 15px;
}

.resume-style4-section-title {
  font-size: 10pt;
  font-weight: 700;
  margin: 0 0 5px 0;
  text-transform: uppercase;
  color: #7AA1CB;
}

.resume-style4-experience-item {
  margin-bottom: 8px;
}

.resume-style4-experience-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;
}

.resume-style4-company-name {
  font-weight: 700;
  margin: 0;
  font-size: 9pt;
}

.resume-style4-job-position {
  margin: 0;
  font-size: 9pt;
}

.resume-style4-job-date {
  font-size: 8pt;
  text-align: right;
}

.resume-style4-indented {
  margin-left: 10px;
}

.resume-style4-right-column a {
  color: #0000FF;
  text-decoration: none;
}

/* resume style 5 */
#resumeStyle5 {
    background-color: white;
    color: black;
    width: 100%;
    height: 11.35in;
    overflow: hidden;
    line-height: 1;
}
#resumeStyle5 a {
    color: #F45B69;
}
#resumeStyle5 a:hover {
    color: whitesmoke;
}
#resumeStyle5 .reviewDefaultResumeSections {
    border-bottom: none;
}
#resumeStyle5 h3, #resumeStyle5 h6 {
    margin: 0.3rem 0;
    line-height: 1;
}
#resumeStyle5LowerWrap {
    display: flex;
    height: 100%;
    width: 100%;
}
#resumeStyle5LeftDiv {
    display: flex;
    flex-direction: column;
    width: 30%;
    height: 100%;
    background-color: #456990;
    color: white;
}
#resumeStyle5LeftDiv h2 {
    color: white;
}
#resumeStyle5RightDiv {
    display: flex;
    flex-direction: column;
    width: 70%;
    height: 100%;
    padding-top: 1%;
}

/* Resume Style 5 specific styles */
.resume-style5-container {
  height: 11.35in;
  width: 100%;
  margin: 0 auto;
  font-family: "Helvetica", "Arial", sans-serif;
  color: #333;
  background: #fff;
  padding: 0;
  box-sizing: border-box;
  font-size: 8pt;
  line-height: 1.2;
}

.resume-style5-content {
  display: flex;
  height: 100%;
}

.resume-style5-left-column {
  width: 30%;
  padding: 15px;
  background-color: #456990;
  color: white;
  height: 100%;
}

.resume-style5-right-column {
  width: 70%;
  padding: 15px;
  height: 100%;
}

.resume-style5-name-title {
  margin-bottom: 20px;
}

.resume-style5-name {
  font-size: 16pt;
  font-weight: 700;
  margin: 0;
  margin-bottom: 8px;
  color: white;
}

.resume-style5-job-title {
  font-size: 12pt;
  font-weight: 600;
  margin: 0;
  margin-bottom: 10px;
  color: white;
}

.resume-style5-section {
  margin-bottom: 15px;
}

.resume-style5-section-title {
  font-size: 10pt;
  font-weight: 700;
  margin: 0 0 8px 0;
  text-transform: uppercase;
  border-bottom: 1px solid #999;
  padding-bottom: 2px;
}

.resume-style5-left-column .resume-style5-section-title {
  color: white;
  border-bottom: 1px solid white;
}

.resume-style5-right-column .resume-style5-section-title {
  color: #456990;
  border-bottom: 1px solid #999;
  color: black;
}

.resume-style5-contact-info {
  display: flex;
  flex-direction: column;
  font-size: 8pt;
}

.resume-style5-contact-info a {
  color: white;
  text-decoration: none;
  margin-bottom: 3px;
  line-height: 1.4;
}

.resume-style5-contact-info a:hover {
  text-decoration: underline;
}

.resume-style5-experience-item {
  margin-bottom: 10px;
}

.resume-style5-experience-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;
}

.resume-style5-company-name {
  font-weight: 700;
  margin: 0;
  font-size: 9pt;
}

.resume-style5-job-position {
  font-style: italic;
  margin: 0;
  font-size: 9pt;
  margin-bottom: 2px;
}

.resume-style5-job-date {
  font-size: 8pt;
  text-align: right;
}

.resume-style5-project-item {
  margin-bottom: 8px;
}

.resume-style5-project-title {
  font-weight: 700;
  margin: 0;
  font-size: 9pt;
  margin-bottom: 2px;
}

/* Container and Header */
.resume-container {
    height: 11.35in;
    width: 100%;
    margin: 0 auto;
    font-family: "Helvetica", "Arial", sans-serif;
    color: #333;
    background: #fff;
    padding: 30px;
    box-sizing: border-box;
}

.resume-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding-bottom: 5px;
    margin-bottom: 10px;
}

.name-and-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
}

.resume-name {
    font-size: 20pt;
    font-weight: 700;
    color: #8BC34A; /* Match the PDF color */
    margin: 0;
    margin-bottom: 5px;
}

.resume-job-title {
    font-size: 12pt;
    font-weight: semi-bold;
    color: #333;
    margin-top: 0;
    margin-bottom: 5px;
    /* font-style: italic; */
}

/* Contact info, displayed horizontally */
.contact-info {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 15px;
    font-size: 8pt;
    margin-top: 5px;
}

.contact-info a {
    color: #333;
    text-decoration: none;
    white-space: nowrap;
}

.contact-info a:hover {
    text-decoration: underline;
}

/* Body: Two columns */
.resume-body {
    display: flex;
    margin-top: 10px;
}

.resume-left-col {
    width: 30%;
    padding-right: 15px;
    border-right: 1px solid #ccc;
}

/* Right column: takes the remaining space */
.resume-right-col {
    width: 70%;
    padding-left: 15px;
    box-sizing: border-box;
}

/* Section spacing */
.resume-section {
    margin-bottom: 10px;
}

.section-title {
    margin: 0 0 5px 0;
    font-size: 10pt;
    font-weight: 700;
    text-transform: uppercase;
    color: #009688;
}

.left-section-title {
    margin: 0 0 5px 0;
    font-size: 10pt;
    font-weight: 700;
    text-transform: uppercase;
    color: #009688;
}

.reviewCoverLetterComponent {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}
.coverLetterCentered {
    text-align: center;
    padding-top: 1%;
    display: flex;
    justify-content: space-evenly;
    width: 40%;
    margin-left: 30%;
    align-items: center;
}
#biCopyButton {
    font-size: x-large;
}
#biCopyButton:hover {
    cursor: pointer;
}

.alerts {
    position: fixed !important;
    align-items: center;
    width: 30%;
    left: 35vw;
    top:25vh;
    text-align: center;
    z-index: 99999 !important;
}
.faqSpan {
    text-decoration: none;
    font-weight: bold;
    color: #F45B69;
    margin-left: .25em;
}
.HomeSectionTitle {
    font-size: 1.25em;
    font-weight: bolder;
    margin: 5%;
}
.HomeSectionSubScript {
    color: lightgrey;
    font-style: italic;
    margin: 5%;
}
.homeNum {
    font-size: large;
    font-weight: bold;
}
.reviewButton { 
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.createResumePlusAndMinus {
    display: flex;
    width:50%;
    align-items: flex-end;
    padding-bottom: 5px;
}
.creationButton:hover {
    cursor: pointer;
}
#CreateResumePlusButton {
    border-radius: .25rem;
    background-color: whitesmoke;
    color: green;
    margin-right: 10px;
}
#CreateResumePlusButton:hover {
    color: #F09440;
}
#CreateResumeMinusButton {
    border-radius: .25rem;
    background-color: whitesmoke;
    color: rgb(206, 23, 23);
}
#CreateResumeMinusButton:hover {
    color: #F09440;
}
.userSetupModalSocialSquish {
    display: flex;
    gap: 10px;
    flex-wrap: nowrap;
}

.userSetupModalSocialSquish .form-control {
    flex: 1;
    min-width: 130px; 
}
.reviewSize {
    overflow-y: hidden;
    height: fit-content;
    max-height: 11.35in;
    overflow: hidden;
}
.social-media-entry {
    border: 1px solid lightgrey;
    border-radius: .25rem;
    padding: 5px;
    margin: 10px;
}
.socialMediaLinks {
    font-size: small;
}
.reviewAddress {
    font-size: small;
}
.reviewResumeStyleToggleButtonGroup{
    display: flex;
    justify-content: space-around;
    width:45%;
}
.initSetupArrow {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 8%;
}
.disabled-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(75, 69, 69, 0.7); /* Semi-transparent overlay */
    z-index: 10; /* Ensures it sits above the pricing table */
    cursor: not-allowed; /* Indicate to the user that interaction is disabled */
}
.socialMediaLinkColor a {
    text-decoration: none;
    color: #F45B69;
}
.socialMediaLinkColor a:hover {
    font-weight: bold;
}
.subscriptionCard {
    width: 75%;
}
.centeredReviewButtonDiv {
    display: flex;
    flex-direction: column;
    width: 40%;
    margin-left: 30%;
    align-items: center;
    justify-content: space-around;
}
.userInfoCardInnerWrap {
    display: flex;
    justify-content: space-evenly;
}
#deleteAcctModal {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-left:10%;
}
.question-icon {
    vertical-align: super;
    margin-left: 4px;
    cursor: pointer;
}
#tailoredInfoCard {
    width:40%;
}
.userContactCardAvatar img{
    width:40%;
}
.userContactCardAvatar {
    margin-bottom: 2%;
}
.heroSection {
    width: 100%;
    padding: 0 5% 2rem 5%; /* Removed top padding, kept sides and bottom */
    text-align: center;
    margin-bottom: 1rem;
}
.heroSubtitle {
    color: #e0e0e0;
    font-size: 1.1rem;
    margin: 0.5rem 0 1rem 0;
}
.heroContent h1 {
    font-size: 2rem;
    font-weight: 600;
    color: white;
    margin-bottom: 0.5rem;
}

.heroStats {
    display: flex;
    justify-content: center;
    gap: 3rem;
    margin: 1rem 0;
}

.statItem {
    display: inline-flex;
    align-items: baseline;
    gap: 0.5rem;
}

.statNumber {
    font-size: 2rem;
    font-weight: 600;
    color: #00b4d8; /* Your bright accent color */
}

.statLabel {
    color: #e0e0e0;
    font-size: 0.9rem;
}

.heroCTA {
    margin-top: 1rem;
}

.heroButton {
    margin: 0 0.5rem;
}

.tailoredResumeMatch {
    text-decoration: none;
    color: #f57784;
}
.tailoredResumeMatch:hover {
    color: whitesmoke;
    cursor: pointer;
    text-decoration: underline;
}

/* Media queries for responsive design */
@media (max-width: 767px) {
    .reviewButton {
        width: 100%;
        flex-direction: column;
        align-items: center;
    }
    
    .reviewResumeStyleToggleButtonGroup {
        width: 100% !important;
        margin-top: 10px;
    }
    
    .modal-dialog.modal-fullscreen {
        margin: 0;
        padding: 0;
    }
}

/* Orientation Alert Styles */
.orientation-alert {
    margin-bottom: 15px;
    border-left: 4px solid #0dcaf0;
}

.orientation-alert-content {
    display: flex;
    align-items: center;
    gap: 10px;
}

.orientation-alert-content svg {
    animation: rotate 2s ease-in-out infinite;
}

@keyframes rotate {
    0% { transform: rotate(0deg); }
    25% { transform: rotate(90deg); }
    50% { transform: rotate(90deg); }
    75% { transform: rotate(0deg); }
    100% { transform: rotate(0deg); }
}

.mobile-pagination {
    margin: 15px 0;
}

.mobile-pagination .pagination {
    flex-wrap: wrap;
    justify-content: center;
    gap: 5px;
}

@media (max-width: 767px) {
    .centeredReviewButtonDiv {
        width: 80% !important;
        margin-left: 10% !important;
    }
    
    .pageWrapper {
        padding-top: 20% !important;
    }
    
    .question-icon {
        margin-left: 2px;
    }
}

/* Table header content container */
.th-content {
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
}

.th-content .question-icon {
    flex-shrink: 0;
    margin-left: 4px;
    position: relative;
    z-index: 1;
}

/* Mobile-specific styles for table headers */
@media (max-width: 767px) {
    .th-content {
        font-size: 0.85rem;
        justify-content: center;
    }
    
    .th-content .question-icon {
        font-size: 0.75rem;
        margin-left: 2px;
    }
}

@media (max-width: 860px) {
    #defaultResumeWrap {
        width: 100%;
        max-width: 497.33px;
        height: 710.47px;
    }
    #tailoredInfoCard {
        width:80%;
        margin-left:10%;
    }
    .pageWrapper .secondaryPageWrapper {
        flex-direction: column;
        flex-wrap: wrap;
    }
    .pageWrapper{
        padding-top: 2.5%;
    }
    .backButton {
        display: none;
    }
    .leftDiv {
        display: none;
    }
    .homeLeftCard .card-body {
        display: none;
    }
    div.head_wrap {
        display: none;
    }
    .leftDiv {
        width: 85%;
    }
    .rightDiv {
        width: 80%;
        margin-top: 10%;
        margin-left: 10%;
    }
    .alerts {
        width: 85%;
        left: 7.5vw;
        top: 15vh;
    }
    .subscriptionPageWrap {
        flex-direction: column;
        margin-top: 10%;
    }
    .subscriptionPageWrap .card {
        width: 80%;
        margin-left: 10%;
    }
    .card-body .mobile-textarea {
        max-width: 100%;
    }
    #jobDescriptionCardInnerDiv {
        width: 80%;
        margin-left: 10%;
    }
    #scratchCardInnerDiv {
        width: 80%;
        margin-left: 10%;
    }
    .heroContent {
        margin-top: 15%;
    }
    button.heroButton.btn.btn-primary {
        margin: 7.5px;
    }
}

/* Mobile Resume Modal Styles */
.mobile-resume-modal {
    margin: 0;
    width: 100vw !important;
    max-width: 100vw !important;
}

.mobile-resume-modal .modal-content {
    border-radius: 0;
    min-height: 100vh;
}

.mobile-review-header {
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.mobile-toggle-group {
    width: 100% !important;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: center !important;
}

.mobile-review-body {
    padding: 0.5rem !important;
    max-height: 75vh !important;
}

.mobile-resume-container {
    transform: scale(0.8);
    transform-origin: top center;
    width: 125%;
    margin-left: -12.5%;
}

.mobile-resume-footer {
    justify-content: center !important;
    gap: 10px;
}

/* Mobile styles for UserProfile */
@media (max-width: 767px) {
    .userInfoCardInnerWrap {
        flex-direction: column;
        align-items: center;
        gap: 15px;
    }
    
    .setupAvatarDiv {
        width: 70%;
        margin-left: 15%;
    }
    
    .userContactCardAvatar img {
        width: 60%;
    }
    
    .leftShifted {
        text-align: center;
    }
    
    .socialMediaLinkColor {
        text-align: center;
        margin-top: 10px;
    }
    
    .cardInnerDiv {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

/* Mobile styles for UserProfile */
@media (max-width: 767px) {
    .userInfoCardInnerWrap {
        flex-direction: column;
    }
    
    .userInfoCardInnerWrap > div {
        width: 100% !important;
    }
    
    .userInfoCardInnerWrap > div:first-child {
        margin-bottom: 20px;
    }
    
    .userInfoCardInnerWrap form {
        width: 100% !important;
    }
    
    .userInfoCardInnerWrap .form-group {
        width: 100% !important;
    }
    
    .userInfoCardInnerWrap .socialMediaLinks {
        flex-direction: column;
        align-items: center;
    }
}

/* Fix for textarea overflow */
textarea, .mobile-textarea {
    width: 100% !important;
    max-width: 100% !important;
    box-sizing: border-box !important;
    overflow-x: hidden;
}

/* Mobile-specific styles for Tailor and Create views */
.mobile-view-container {
    padding: 0 15px;
    margin-top: 20px;
}

/* Portrait mode (default) */
@media (max-width: 767px) {
    .mobile-view-container {
        padding: 0 10px;
        margin-top: 10px;
    }
    
    /* Adjust padding for better mobile experience */
    .pageWrapper {
        padding-top: 75px !important;
    }
    
    /* Make cards take full width on mobile */
    #jobDescriptionCardInnerDiv,
    #scratchCardInnerDiv {
        width: 100% !important;
        margin-left: 0 !important;
        padding: 0 5px;
    }
    
    /* Improve form elements on mobile */
    .mobile-textarea, textarea {
        font-size: 16px !important; /* Prevents iOS zoom on focus */
        padding: 8px !important;
    }
    
    /* Improve form layout on mobile */
    .experience-entry .form-group {
        flex-direction: column !important;
    }
    
    /* Ensure buttons are properly sized on mobile */
    .btn-lg {
        padding: 10px 15px;
        font-size: 16px;
    }
    
    /* Improve touch targets */
    .question-icon {
        padding: 5px;
    }
    
    /* Improve experience entry layout */
    .experience-entry {
        padding: 10px !important;
        margin-bottom: 15px !important;
    }
}

/* Landscape mode - better utilize card space */
@media (max-width: 915px) and (orientation: landscape) {
    .pageWrapper {
        padding-top: 15px !important;
    }
    
    /* Maximize card width in landscape */
    #jobDescriptionCardInnerDiv,
    #scratchCardInnerDiv {
        width: 95% !important;
        margin: 0 auto !important;
        max-width: none !important;
    }
    
    /* Maximize horizontal space */
    .card {
        width: 100% !important;
        max-width: none !important;
    }
    
    .card-body {
        padding: 0.75rem !important;
    }
    
    /* Make form elements use available width */
    .form-control, textarea {
        width: 100% !important;
    }
    
    /* Adjust form layout for landscape orientation */
    .experience-entry .form-group {
        flex-direction: row !important;
        justify-content: space-between !important;
    }
    
    /* Ensure inputs take proper width */
    .form-group .form-control {
        width: 100% !important;
    }
}

/* Mobile Resume Modal Styles */
.mobile-resume-modal {
    margin: 0;
    width: 100vw !important;
    max-width: 100vw !important;
}

.mobile-resume-modal .modal-content {
    border-radius: 0;
    min-height: 100vh;
}

.mobile-review-header {
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.mobile-toggle-group {
    width: 100% !important;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: center !important;
}

.mobile-review-body {
    padding: 0.5rem !important;
    max-height: 75vh !important;
}

.mobile-resume-container {
    transform: scale(0.8);
    transform-origin: top center;
    width: 125%;
    margin-left: -12.5%;
}

.mobile-resume-footer {
    justify-content: center !important;
    gap: 10px;
}

/* resume style 6 */
#resumeStyle6 {
    background-color: white;
    padding: 20px;
    font-family: 'Arial', sans-serif;
}

.resume-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
}

.resume-header {
    text-align: center;
    margin-bottom: 20px;
}

.resume-name {
    color: #8BC34A;
    font-size: 24px;
    margin-bottom: 5px;
}

.resume-job-title {
    color: #333;
    font-size: 16px;
    margin-top: 0;
}

.contact-info {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px;
    margin-top: 10px;
}

.contact-info a:hover {
    text-decoration: underline;
}

.resume-address-section {
    text-align: center;
    margin-bottom: 20px;
}

.resume-address-section .section-title {
    color: #009688;
    font-size: 14px;
    margin-bottom: 5px;
    text-transform: uppercase;
}