.head_wrap {
    display: flex;
    justify-content: space-around;
    width:100%;
    height: 21%;
    top: 0;
    padding-bottom: 8%;
    color: white;
    position: fixed;
    z-index: 999;
}
.Login_head_wrap {
    display: flex;
    justify-content: space-evenly;
    position: fixed;
    width:100%;
    left: 0;
    height: 15%;
    top: 0;
    z-index: 999;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.loginHeaderMid {
    display: flex;
    justify-content:space-evenly;
    width: 30%;
    align-items: center;
}
#loginLogoSmall2 {
    width: 40%;
}
.mobileHeader {
    display: none;
}
.headerLeft {
    width: 30%;
}
.divWrap {
    margin-top: 5%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
#topUl {
    padding-top: 5%;
    justify-content: center;
}

/* Screen reader only class for accessibility */
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
}

.headerMid {
    display: flex;
    flex-direction: column;
    width: 40%;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 1%;
}
.headerRight {
    width: 30%;
    display: flex;
    flex-direction: column;
    margin-top: 1%;
}
.headerRight a {
    text-decoration: none;
    color: #F45B69;
}
.headerRight a:hover {
    color: #E4FDE1;
}
#LogoSmall2 {
    width:15%;
}
#LogoSmall2:hover {
    cursor: pointer;
}
#userAvatar {
    border: 3px solid transparent;
}
#userAvatar:hover{
    cursor: pointer;
    /* border: 3px solid #E88873; */
}
#noProfile{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
#noProfile:hover {
    cursor: pointer;
}
.logobkg {
    display: flex;
    justify-content: space-around;
    align-items: center;
}
#loginOrRegisterTags {
    margin:0;
}
#modeWord {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
h1 {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.avatarWindow {
    border-radius: 5%;
    padding: 5px;
    width: 150px;
    height: 160px;
    margin-left: 45%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    border: 2px solid #E4FDE1;
}
.avatarWindow img {
    height: 65%;
    width: 65%;
    border-radius: 50%;
    margin-top: 5%;
}
.backButton {
    position: fixed;
    left: 2.5%;
    top: 27%;
    z-index: 1;
    height: 2.5em;
    width: 2.5em;
    padding: .25em;
    border-radius: 50%;
}
.backButton:hover {
    cursor: pointer;
}
.AlertMessageIconDiv {
    display: block; 
    position: absolute; 
    margin: 0 0 -1% 5%; 
    z-index: 999; 
    color: orangered;
}
@media only screen and (max-width: 850px) {
    .head_wrap {
        display: none;
    }
    .mobileHeader {
        display: flex;
    }
} 