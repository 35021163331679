.loadingPageWrapper {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.85);
    color: whitesmoke;
    position: fixed;
    z-index: 9999;
}
.loadingImgDiv {
    width: 10%;
}
.loadingImgDiv img{
    width: 100%;
}

/* Add a fade-in animation for smoother appearance */
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.loadingPageWrapper {
    animation: fadeIn 0.3s ease-in-out;
}

/* Add responsive styling for mobile devices */
@media (max-width: 768px) {
    .loadingImgDiv {
        width: 20%;
        min-width: 60px;
    }
}